import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import colors from './colors';
import fonts from './fonts';
import styles from './styles';
import Checkbox from './components/checkbox';
import Button from './components/button';
import RippleBox from './components/ripplebox';
import Accordion from './components/accordion';
import Input from './components/input';
import FormLabel from './components/form-label';
import Radio from './components/radio';
import Heading from './components/heading';
import Switch from './components/switch';
import radii from './radius';
import { Menu } from './components/menu';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};
const overrides = {
  colors,
  fonts,
  styles,
  components: {
    Button,
    Checkbox,
    Accordion,
    RippleBox,
    Input,
    FormLabel,
    Switch,
    Radio,
    Menu,
    Heading,
  },
  radii,
};
const theme = extendTheme({ config, ...overrides });

export default theme;
