import invertKeysValuesFromObject from '~/utils/invertKeysValuesFromObject';
import { flow, map, fromPairs } from 'lodash/fp';
import {
  BACKEND_ACCOUNT_TYPES_MAPPING,
  NOT_US_ACCOUNT_TYPES,
  US_ACCOUNT_TYPES,
} from '../../utils/accountTypes';
import SPC_FOUNDATION_US from './payload_SPC_Foundation_US.json';
import SPC_FOUNDATION_NON_US from './payload_SPC_Foundation_NON_US.json';
import SPC_CORPORATION_US from './payload_SPC_Corporation_US.json';
import SPC_CORPORATION_NON_US from './payload_SPC_Corporation_NON_US.json';
import SPC_BENEFIT_PLAN_US from './payload_SPC_BenefitPlan_US.json';
import SPC_BENEFIT_PLAN_NON_US from './payload_SPC_BenefitPlan_NON_US.json';
import SPC_CHARITABLE_TRUST_US from './payload_SPC_CharitableTrust_US.json';
import SPC_ENDOWMENT_US from './payload_SPC_Endowment_US.json';
import SPC_ENDOWMENT_NON_US from './payload_SPC_Endowment_NON_US.json';
import LP_INDIVIDUAL_US from './payload_LP_Individual_US.json';
import LP_INDIVIDUAL_US_API_ERROR from './payload_LP_Individual_US_api_error.json';
import SPC_INDIVIDUAL_NON_US from './payload_SPC_Individual_NON_US.json';
import LP_IRREVOCABLE_TRUST_US from './payload_LP_IrrevocableTrust_US.json';
import LP_REVOCABLE_TRUST_US from './payload_LP_RevocableTrust_US.json';
import SPC_IRA_US from './payload_SPC_IRA_US.json';
import LP_LLC_US from './payload_LP_LLC_US.json';
import SPC_LLC_NON_US from './payload_SPC_LLC_NON_US.json';
import LP_PARTNERSHIP_US from './payload_LP_Partnership_US.json';
import SPC_TENANTS_IN_COMMON_NON_US from './payload_SPC_TenantsInCommon_NON_US.json';
import LP_TENANTS_IN_COMMON_US from './payload_LP_TenantsInCommon_US.json';
import LP_JOINT_TENANTS_WITH_RIGHTS_OF_SURVIVORSHIP_US from './payload_LP_JointTenantsWithRightsOfSurvivorship_US.json';
import SPC_JOINT_TENANTS_WITH_RIGHTS_OF_SURVIVORSHIP_NON_US from './payload_SPC_JointTenantsWithRightsOfSurvivorship_NON_US.json';
import COPA_LP_INDIVIDUAL_US from './payload_COPA_LP_Individual_US.json';
import COPA_LP_JTWROS_US from './payload_COPA_LP_JTWROS_US.json';
import COPA_LP_IRA_US from './payload_COPA_LP_IRA_US.json';
import COPA_LP_LLC_US from './payload_COPA_LP_LLC_US.json';
import { NewSubscriptionFormData } from '../../utils/types';

const backendMappedValuesInverted = invertKeysValuesFromObject(
  BACKEND_ACCOUNT_TYPES_MAPPING,
);

const revertMappedToBackend = (payload: NewSubscriptionFormData) => ({
  ...payload,
  account_type:
    backendMappedValuesInverted[payload.account_type] || payload.account_type,
});

const formatPayloadData = <ResultType = unknown>(data: {}) =>
  flow(
    Object.entries,
    map(([key, value]) => [key, revertMappedToBackend(value)]),
    fromPairs,
  )(data) as ResultType;

const mapAccountTypeToLabel = (accountType: string) =>
  ({
    [accountType]: accountType,
    [US_ACCOUNT_TYPES.irrevocableTrust]: 'Trust - Irrev',
    [US_ACCOUNT_TYPES.revocableTrust]: 'Trust - Rev',
    [US_ACCOUNT_TYPES.tenantsInCommon]: 'Joint - Tenants in Common',
    [US_ACCOUNT_TYPES.tenantsSurvivorship]: 'Joint - JTWROS',
  }[accountType]);

const apiIndividualErrorLabel =
  `API Error: ${US_ACCOUNT_TYPES.individual}` as const;

const copaIndividualLabel = `COPA: ${US_ACCOUNT_TYPES.individual}` as const;
const copaJTWROSLabel =
  `COPA: ${US_ACCOUNT_TYPES.tenantsSurvivorship}` as const;

type UsLpAccountTypesPayloads = {
  [US_ACCOUNT_TYPES.individual]: {};
  [copaIndividualLabel]: {};
  [copaJTWROSLabel]: {};
  [apiIndividualErrorLabel]: {};
  [US_ACCOUNT_TYPES.llc]: {};
  [US_ACCOUNT_TYPES.partnership]: {};
  [US_ACCOUNT_TYPES.corporation]: {};
  'Trust - Irrev': {};
  'Joint - Tenants in Common': {};
  'Joint - JTWROS': {};
  'Trust - Rev': {};
};

type UsSpcAccountTypesPayloads = {
  [US_ACCOUNT_TYPES.benefitPlan]: {};
  [US_ACCOUNT_TYPES.charitableTrust]: {};
  [US_ACCOUNT_TYPES.corporation]: {};
  [US_ACCOUNT_TYPES.endowment]: {};
  [US_ACCOUNT_TYPES.foundation]: {};
  [US_ACCOUNT_TYPES.ira]: {};
};

type NonUsAccountTypesPayloads = {
  [NOT_US_ACCOUNT_TYPES.benefitPlan]: {};
  [NOT_US_ACCOUNT_TYPES.corporation]: {};
  [NOT_US_ACCOUNT_TYPES.endowment]: {};
  [NOT_US_ACCOUNT_TYPES.foundation]: {};
  [NOT_US_ACCOUNT_TYPES.individual]: {};
  [NOT_US_ACCOUNT_TYPES.llc]: {};
  'Joint - Tenants in Common': {};
  'Joint - JTWROS': {};
};

const US_LP_ACCOUNT_TYPES_PAYLOADS: UsLpAccountTypesPayloads =
  formatPayloadData<UsLpAccountTypesPayloads>({
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.individual)]: LP_INDIVIDUAL_US,
    [`COPA: ${mapAccountTypeToLabel(US_ACCOUNT_TYPES.individual)}`]:
      COPA_LP_INDIVIDUAL_US,
    [`COPA: ${mapAccountTypeToLabel(US_ACCOUNT_TYPES.tenantsSurvivorship)}`]:
      COPA_LP_JTWROS_US,
    [`COPA: ${mapAccountTypeToLabel(US_ACCOUNT_TYPES.ira)}`]: COPA_LP_IRA_US,
    [`COPA: ${mapAccountTypeToLabel(US_ACCOUNT_TYPES.llc)}`]: COPA_LP_LLC_US,
    [`API Error: ${mapAccountTypeToLabel(US_ACCOUNT_TYPES.individual)}`]:
      LP_INDIVIDUAL_US_API_ERROR,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.irrevocableTrust)]:
      LP_IRREVOCABLE_TRUST_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.llc)]: LP_LLC_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.partnership)]: LP_PARTNERSHIP_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.revocableTrust)]:
      LP_REVOCABLE_TRUST_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.tenantsInCommon)]:
      LP_TENANTS_IN_COMMON_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.tenantsSurvivorship)]:
      LP_JOINT_TENANTS_WITH_RIGHTS_OF_SURVIVORSHIP_US,
  });

const US_SPC_ACCOUNT_TYPES_PAYLOADS: UsSpcAccountTypesPayloads =
  formatPayloadData<UsSpcAccountTypesPayloads>({
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.benefitPlan)]: SPC_BENEFIT_PLAN_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.charitableTrust)]:
      SPC_CHARITABLE_TRUST_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.corporation)]: SPC_CORPORATION_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.endowment)]: SPC_ENDOWMENT_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.foundation)]: SPC_FOUNDATION_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.ira)]: SPC_IRA_US,
  });

const NON_US_ACCOUNT_TYPES_PAYLOADS: NonUsAccountTypesPayloads =
  formatPayloadData<NonUsAccountTypesPayloads>({
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.benefitPlan)]:
      SPC_BENEFIT_PLAN_NON_US,
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.corporation)]:
      SPC_CORPORATION_NON_US,
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.endowment)]:
      SPC_ENDOWMENT_NON_US,
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.foundation)]:
      SPC_FOUNDATION_NON_US,
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.individual)]:
      SPC_INDIVIDUAL_NON_US,
    [mapAccountTypeToLabel(NOT_US_ACCOUNT_TYPES.llc)]: SPC_LLC_NON_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.tenantsInCommon)]:
      SPC_TENANTS_IN_COMMON_NON_US,
    [mapAccountTypeToLabel(US_ACCOUNT_TYPES.tenantsSurvivorship)]:
      SPC_JOINT_TENANTS_WITH_RIGHTS_OF_SURVIVORSHIP_NON_US,
  });

const ALL_ACCOUNT_TYPES_PAYLOADS = {
  ...US_LP_ACCOUNT_TYPES_PAYLOADS,
  ...US_SPC_ACCOUNT_TYPES_PAYLOADS,
  ...NON_US_ACCOUNT_TYPES_PAYLOADS,
} as const;

export type AllBeAccountTypesMappingValues =
  keyof typeof ALL_ACCOUNT_TYPES_PAYLOADS;

export {
  US_LP_ACCOUNT_TYPES_PAYLOADS,
  US_SPC_ACCOUNT_TYPES_PAYLOADS,
  NON_US_ACCOUNT_TYPES_PAYLOADS,
  ALL_ACCOUNT_TYPES_PAYLOADS,
};
