/* eslint-disable camelcase */
import { BadActorBEData, BadActorFEData, BadActorData } from './badActorTypes';

export const corientBadActorBEDefaults: BadActorBEData = {
  bad_actor: {
    ba_misdemeanor: '',
    ba_judgement_court: '',
    ba_us_securities_officer: '',
    ba_sec: '',
    ba_sec_5y: '',
    ba_suspended_expelled: '',
    ba_underwriter: '',
    ba_us_post_office: '',
  },
};

export const corientBadActorFEDefaults: BadActorFEData = {
  fe_only_has_subscriber_done_bad_acts: '',
};

export const corientBadActorDefaults: BadActorData = {
  ...corientBadActorBEDefaults,
  ...corientBadActorFEDefaults,
};
