import { Box, keyframes, ThemingProps, useStyleConfig } from '@chakra-ui/react';
import { ForwardedRef, MouseEvent } from 'react';

import { RIPPLE_EFFECT_DURATION } from '~/theme/components/ripplebox';

type RippleEffectProps = {
  button: HTMLButtonElement;
  event: MouseEvent<HTMLButtonElement>;
  variant: ThemingProps<string>;
  ref: ForwardedRef<HTMLInputElement>;
};

const RippleEffect = ({ button, event, variant, ref }: RippleEffectProps) => {
  const rippleAnimation = keyframes`
      to {
        transform: scale(4);
        opacity: 0;
      }
    `;

  const animation = `${rippleAnimation} 1 ${
    RIPPLE_EFFECT_DURATION / 1000
  }s linear`;

  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  const left = event.clientX - button.offsetLeft - radius;
  const right = event.clientY - button.offsetTop - radius;

  const styles = useStyleConfig('RippleBox', variant);

  return (
    <>
      {button && event ? (
        <Box
          __css={styles}
          w={`${diameter}px`}
          h={`${diameter}px`}
          left={`${left}px`}
          top={`${right}px`}
          animation={animation}
          ref={ref}
        />
      ) : null}
    </>
  );
};

export default RippleEffect;
