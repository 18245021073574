import { useState } from 'react';
import { useInterval } from 'react-use';
import { useRouter } from 'next/router';

const useMaintenanceModeRefreshManager = () => {
  const router = useRouter();
  const [buildId, setBuildId] = useState<string | null>(null);

  const INTERVAL_TIME = 60 * 1000;

  useInterval(async () => {
    const response = await fetch('/api/build-id');

    await response
      .json()
      .then(data => {
        const { buildId: newBuildId } = data;
        setBuildId(newBuildId);
      })
      .catch(() => {
        if (buildId) router.reload();
      });
  }, INTERVAL_TIME);

  return null;
};

export default useMaintenanceModeRefreshManager;
