import { useController, useFormContext } from 'react-hook-form';
import {
  ActionMeta,
  GroupBase,
  createFilter,
  SelectInstance,
} from 'chakra-react-select';
import { useRef } from 'react';
import callAllFns from '~/utils/callAllFns';
import useSecondaryTextThemeColor from '~/hooks/themeColors/useSecondaryTextThemeColor';
import gtag from '~/lib/gtag';
import useValidateFieldWithErrorOnChange from '~/components/Form/hooks/useValidateFieldWithErrorOnChange';
import colors from '~/theme/colors';
import { OptionType, SearchSelectProps } from '../../types';
import useClearSelectInputValueOnForcedClear from './hooks/useClearSelectInputValueOnForcedClear';
import getComponents from './utils/getComponents';
import useSelectCustomStyle from './hooks/useSelectCustomStyle';

type UseSearchSelectProps = {
  name: string;
};

type GetSearchSelectProps = SearchSelectProps;

/**
 *
 * DO NOT MAKE ANY CHANGES TO THIS HOOK!
 * Unless you wanna change all components dependent of it (MultiSearchSelect, PaginatedSearchSelect, SearchSelect, CreatableSearchSelect)
 * to make punctual changes pass them as prop to this hook in the specific component you want to change
 *
 */
const useSearchSelect = ({ name }: UseSearchSelectProps) => {
  const { field } = useController({ name });
  const {
    onChange: onChangeSelect,
    value,
    ref: fieldRef,
    ...selectField
  } = field;

  useValidateFieldWithErrorOnChange(name);

  const { resetField, control } = useFormContext();

  const secondaryTextColor = useSecondaryTextThemeColor();

  const selectInputRef =
    useRef<SelectInstance<any, boolean, GroupBase<unknown>>>();

  const handleSelectOnchange = (
    option: OptionType,
    event?: ActionMeta<OptionType | unknown>,
  ) => {
    if (event?.action === 'clear' && option === null && event?.name) {
      resetField(event?.name);
      return;
    }
    if (!option) return;

    onChangeSelect(option.value);

    /**
     * Clear Input Value after selection so it keeps the input state clean for new searches
     */
    if (selectInputRef?.current?.props?.inputName)
      resetField(selectInputRef?.current?.props?.inputName, {
        defaultValue: '',
      });
  };

  useClearSelectInputValueOnForcedClear({
    name,
    selectInputRef,
  });

  const { getSelectCustomStyles } = useSelectCustomStyle();

  const getSearchSelectProps = (
    {
      onChange,
      options,
      filterMachFromStart,
      label,
      width = '100%',
      height,
      components,
      ...props
    }: GetSearchSelectProps = {} as any,
  ) => {
    const optionsWithoutEmptyValues = options?.filter(
      option => !(!option?.value && !option?.label),
    );

    const selectedOption = options?.find(
      option => option.value === value && !!option.label,
    );
    const filterConfig = createFilter({
      matchFrom: filterMachFromStart ? 'start' : 'any',
      stringify: option => `${option.label}`,
    });

    const handleGaEvent = () =>
      gtag.event({
        action: `Search ${label}`,
        category: 'Button',
        label: 'Additional Subscription Form',
      });

    // eslint-disable-next-line no-underscore-dangle
    const defaultValue = control._defaultValues[name];
    const searchSelectComponents = getComponents(components);

    return {
      ...selectField,
      onChange: callAllFns(handleSelectOnchange, onChange as any) as any,
      id: name,
      key: `${name}_key`,
      ref: (e: any) => {
        fieldRef(e);
        selectInputRef.current = e;
      },
      label,
      filterOption: filterConfig,
      isClearable: value !== defaultValue,
      onFocus: handleGaEvent,
      value: selectedOption,
      placeholderColor: secondaryTextColor,
      placeholder: '',
      backspaceRemovesValue: true,
      selectedOptionColorScheme: colors.brand.perwinkle[500],
      chakraStyles: getSelectCustomStyles({ width, height }),
      options: optionsWithoutEmptyValues,
      ...searchSelectComponents,
      ...props,
    } as const;
  };

  return { getSearchSelectProps };
};

export default useSearchSelect;
