import { MenuListProps, components } from 'chakra-react-select';
import React, { memo, useCallback } from 'react';
import useBgThemeColor from '~/hooks/themeColors/useBgThemeColor';
import CustomLoadingMenuFooter from './CustomLoadingMenuFooter';

const CustomMenuList = (props: MenuListProps) => {
  const { children, selectProps, innerProps, innerRef } = props;
  const { onMenuListEndReached, isFetchingNextPage, isLoading } = selectProps;

  const handleMenuListOnScrollEvent = useCallback(ref => {
    if (
      ref.currentTarget.scrollTop + 200 >
      ref.currentTarget.scrollHeight - ref.currentTarget.offsetHeight
    )
      onMenuListEndReached?.();
  }, []);
  const primaryBgColor = useBgThemeColor();

  return (
    <components.MenuList
      {...props}
      innerRef={innerRef}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps,
          backgroundColor: primaryBgColor,
          padding: '0px',
        },
        onScroll: handleMenuListOnScrollEvent,
        'aria-label': 'menu-list-paginated-dropdown',
      }}>
      {children}
      {isFetchingNextPage && !isLoading && <CustomLoadingMenuFooter />}
    </components.MenuList>
  );
};

export default memo(CustomMenuList);
