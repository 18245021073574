import {
  AllocationNewIssues,
  AllocationNewIssuesKeys,
} from './allocationNewIssuesTypes';

export const allocationNewIssuesDefaults: AllocationNewIssues = {
  fe_only_copa_new_issues_interested_in_participating: '',
  fe_only_copa_new_issues_is_exempt_entity: '',
  fe_only_copa_new_issues_is_exempt_entity_or_covered_or_restricted_person: '',
  fe_only_copa_new_issues_is_restricted_person: '',
  fe_only_copa_new_issues_subscriber_beneficial_owner: '',
  fe_only_copa_new_issues_covered_person: '',
  fe_only_copa_new_issues_covered_person_status: '',

  fe_only_copa_new_issues_cp_full_legal_name: '',
  fe_only_copa_new_issues_pub_tin: '',

  fe_only_copa_new_issues_cp_full_legal_name_non_public_co: '',
  fe_only_copa_new_issues_non_pub_tin: '',

  fe_only_copa_new_issues_subscriber_handle_new_issues: '',

  fe_only_copa_new_issues_legal_name_co_1: '',
  fe_only_copa_new_issues_ticker_symbol_1: '',
  fe_only_copa_new_issues_agg_percentage_share_1: '',
};

export const allocationNewIssuesKeysDefaults = Object.keys(
  allocationNewIssuesDefaults,
) as AllocationNewIssuesKeys[];
