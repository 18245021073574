import colors from '~/theme/colors';

const RippleBox = {
  variants: {
    solid: () => ({
      bgColor: colors.brand.perwinkle['500.o25.dark'],
    }),
    outline: () => ({
      bgColor: colors.brand.perwinkle['500.o50.light'],
    }),
    text: () => ({
      bgColor: colors.brand.perwinkle['500.o50.light'],
    }),
  },
  baseStyle: {
    display: 'block',
    position: 'absolute',
    borderRadius: '50%',
    transform: 'scale(0)',
  },
  defaultProps: {
    variant: 'solid',
  },
};

export const RIPPLE_EFFECT_DURATION = 500;

export default RippleBox;
