/* eslint-disable camelcase */
import {
  CorientPayToPlayBEData,
  CorientPayToPlayFEData,
  CorientPayToPlayData,
} from './corientPayToPlayTypes';

export const corientPayToPlayBEDefaults: CorientPayToPlayBEData = {
  pay_to_play: {
    ptp_govt_entity: '',
    ptp_trustee_custodian_description: '',
    ptp_owned_govt_entity_description: '',
    ptp_paragraph_1_2: '',
    ptp_data_labels: [],
  },
};

export const corientPayToPlayFEDefaults: CorientPayToPlayFEData = {
  fe_only_ptp_q_is_gov_entity_or_affiliated: '',
  fe_only_ptp_q_is_gov_entity: '',
  fe_only_ptp_q_subscriber_represents_that_is: '',
  fe_only_ptp_q_name_of_government_entity: '',
  fe_only_ptp_q_rule_206: '',
  fe_only_ptp_q_describe_pay_to_play_laws: '',
};

export const corientPayToPlayDefaults: CorientPayToPlayData = {
  ...corientPayToPlayBEDefaults,
  ...corientPayToPlayFEDefaults,
};
