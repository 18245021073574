import { useColorModeValue } from '@chakra-ui/react';
import colors from '~/theme/colors';

const useSecondaryTextThemeColor = () => {
  const secondaryTextColors = useColorModeValue(
    colors.gray[870],
    colors.gray[700],
  );
  return secondaryTextColors;
};
export default useSecondaryTextThemeColor;
