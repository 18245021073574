const invertKeysValuesFromObject = (obj: {}): { [key: string]: string } => {
  const objectEntries = Object.entries(obj) as [string, string][];

  return objectEntries.reduce(
    (accumulator, [key, value]: [string, string]) => ({
      ...accumulator,
      [value]: key,
    }),
    {},
  );
};

export default invertKeysValuesFromObject;
