export const JOINT_TYPES = {
  tenantsSurvivorship: 'joint tenants with rights of survivorship',
  tenantsInCommon: 'tenants in common',
} as const;

export const INDIVIDUAL_OR_JOINT = {
  individual: 'individual',
  joint: 'joint',
} as const;

export type IndividualOrJointOptions = keyof typeof INDIVIDUAL_OR_JOINT;

export const NOT_TAX_ADVANTAGED_ENTITY_TYPES = {
  corporation: 'corporation',
  partnership: 'partnership',
  llc: 'llc',
  irrevocableTrust: 'irrevocable trust',
} as const;

export const TAX_ADVANTAGED_ENTITY_TYPES = {
  benefitPlan: 'benefit plan',
  endowment: 'endowment',
  foundation: 'foundation',
  corporation: 'corporation',
  charitableTrust: 'charitable trust',
  keogh: 'keogh',
} as const;

export const ONSHORE_ENTITY_TYPES = {
  ...NOT_TAX_ADVANTAGED_ENTITY_TYPES,
} as const;

export const NOT_US_ENTITY_TYPES = {
  corporation: 'corporation',
  foundation: 'foundation',
  endowment: 'endowment',
  benefitPlan: 'benefit plan',
  llc: 'llc',
} as const;

export const OFFSHORE_ENTITY_TYPES = {
  ...NOT_US_ENTITY_TYPES,
  ...TAX_ADVANTAGED_ENTITY_TYPES,
} as const;

export const ENTITY_TYPES = {
  ...ONSHORE_ENTITY_TYPES,
  ...OFFSHORE_ENTITY_TYPES,
} as const;

export const INDIVIDUAL_OR_ENTITY = {
  individual: 'individual',
  entity: 'entity',
} as const;

export const BENEFIT_PLAN_TYPES = {
  erisaPlan: { label: 'ERISA Plan', value: 'erisa_plan' },
  notErisaPlan: {
    label: 'Qualified Plan not subject to ERISA',
    value: 'erisa_qualified_not_erisa',
  },
  notInsuranceErisa: {
    label:
      'Entity (other than an insurance company general account) holding plan assets of ERISA Plans',
    value: 'erisa_entity',
  },
  notInsuranceNotErisa: {
    label:
      'Entity (other than an insurance company general account) holding plan assets of Qualified Plans which are not subject to ERISA (but no plan assets of any ERISA Plan)',
    value: 'erisa_entity_not_erisa',
  },
  insurancePlanAssets: {
    label:
      'An insurance company general account whose assets include “plan assets”',
    value: 'erisa_plan_assets',
  },
} as const;

type BenefitPlanTypeOptionsNames = keyof typeof BENEFIT_PLAN_TYPES;
export type BenefitPlanTypeOptions =
  typeof BENEFIT_PLAN_TYPES[BenefitPlanTypeOptionsNames]['value'];

export const INDIVIDUAL_ONSHORE_ACCOUNT_TYPES = {
  ira: 'ira' as const,
  revocableTrust: 'revocable trust' as const,
  individual: INDIVIDUAL_OR_JOINT.individual,
  ...JOINT_TYPES,
};

export const INDIVIDUAL_OFFSHORE_ACCOUNT_TYPES = {
  individual: INDIVIDUAL_OR_JOINT.individual,
  ...JOINT_TYPES,
};

export const INDIVIDUAL_ACCOUNT_TYPES = {
  ...INDIVIDUAL_ONSHORE_ACCOUNT_TYPES,
  ...INDIVIDUAL_OFFSHORE_ACCOUNT_TYPES,
};

export const US_ACCOUNT_TYPES = {
  ...INDIVIDUAL_ACCOUNT_TYPES,
  ...NOT_TAX_ADVANTAGED_ENTITY_TYPES,
  ...TAX_ADVANTAGED_ENTITY_TYPES,
};

export const NOT_US_ACCOUNT_TYPES = {
  ...NOT_US_ENTITY_TYPES,
  ...INDIVIDUAL_OR_JOINT,
};

export const ONSHORE_OR_OFFSHORE_TYPES = {
  onshoreLp: 'Onshore - GLASfunds LP',
  offshoreSpc: 'Offshore - GLASfunds SPC',
} as const;

export const ALL_ACCOUNT_TYPES = {
  ...INDIVIDUAL_ACCOUNT_TYPES,
  ...ENTITY_TYPES,
};

export const BACKEND_ACCOUNT_TYPES_MAPPING: { [key: string]: string } = {
  [ALL_ACCOUNT_TYPES.ira]: 'IRA',
  [ALL_ACCOUNT_TYPES.individual]: 'Individual',
  [ALL_ACCOUNT_TYPES.benefitPlan]: 'Benefit Plan',
  [ALL_ACCOUNT_TYPES.llc]: 'LLC',
  [ALL_ACCOUNT_TYPES.foundation]: 'Foundation',
  [INDIVIDUAL_OR_JOINT.joint]: 'Joint',
  [ALL_ACCOUNT_TYPES.tenantsInCommon]: 'Joint Tenants In Common',
  [ALL_ACCOUNT_TYPES.tenantsSurvivorship]:
    'Joint Tenants with Rights of Suvivorship',
  [ALL_ACCOUNT_TYPES.endowment]: 'Endowment',
  [ALL_ACCOUNT_TYPES.corporation]: 'Corporation',
  [ALL_ACCOUNT_TYPES.partnership]: 'Partnership',
  [ALL_ACCOUNT_TYPES.revocableTrust]: 'Trust - Revocable',
  [ALL_ACCOUNT_TYPES.irrevocableTrust]: 'Trust - Irrevocable',
  [ALL_ACCOUNT_TYPES.charitableTrust]: 'Trust - Irrevocable',
} as const;

export const BACKEND_CLIENTS_ACCOUNT_TYPES: { [key: string]: string } = {
  ...BACKEND_ACCOUNT_TYPES_MAPPING,
  [ALL_ACCOUNT_TYPES.charitableTrust]: 'Trust',
  lp: 'LP',
  instrumentalityOfTheStateOfOhio: 'Instrumentality of the State of Ohio',
  communityProperty: 'Community Property',
};
