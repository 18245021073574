import { chakraComponents, InputProps } from 'chakra-react-select';
import { useController } from 'react-hook-form';

export type CustomInputProps = {} & InputProps;

const Input = ({ onChange, ...props }: CustomInputProps) => {
  const { inputName, disableAutoComplete, name } = props.selectProps;

  const {
    field: { onChange: onchangeField },
  } = useController({
    name: inputName || '',
    defaultValue: '',
  });

  return (
    <chakraComponents.Input
      {...props}
      onChange={e => {
        onChange?.(e);
        onchangeField(e);
      }}
      aria-label={
        inputName ? `select_input_${inputName}` : `${name}_search_select`
      }
      isHidden={false}
      autoComplete={disableAutoComplete ? 'nope' : undefined}
    />
  );
};
export default Input;
