import create, { StateCreator } from 'zustand';
import { newSubscriptionDefaults } from '../utils/defaults';
import { NewSubscriptionFormData } from '../utils/types';

export interface NewSubscriptionState {
  newSubscriptionData: NewSubscriptionFormData;
  setNewSubscriptionState: (data: NewSubscriptionFormData) => void;
  selectedSubscriptionDraftId: string;
  setSelectedSubscriptionDraftIdState: (
    selectedSubscriptionDraftId: string,
  ) => void;
  hasOpenedABulkUploadDraft: boolean;
  setHasOpenedABulkUploadDraftState: (
    hasOpenedABulkUploadDraft: boolean,
  ) => void;
  clearNewSubscriptionState: () => void;
}

export const createNewSubscriptionSlice: StateCreator<
  NewSubscriptionState
> = set => ({
  newSubscriptionData: newSubscriptionDefaults,
  selectedSubscriptionDraftId: '',
  hasOpenedABulkUploadDraft: false,
  setSelectedSubscriptionDraftIdState: selectedSubscriptionDraftId =>
    set({ selectedSubscriptionDraftId }),
  setNewSubscriptionState: data => set({ newSubscriptionData: data }),
  setHasOpenedABulkUploadDraftState: hasOpenedABulkUploadDraft =>
    set({ hasOpenedABulkUploadDraft }),
  clearNewSubscriptionState: () =>
    set({
      newSubscriptionData: newSubscriptionDefaults,
      selectedSubscriptionDraftId: '',
      hasOpenedABulkUploadDraft: false,
    }),
});
const useNewSubscriptionStore = create<NewSubscriptionState>()((...a) => ({
  ...createNewSubscriptionSlice(...a),
}));

export default useNewSubscriptionStore;
