import { Select } from 'chakra-react-select';
import useSearchSelect from '../hooks/useSearchSelect';
import SelectWrapper from '../SelectWrapper';
import { OptionType, SearchSelectProps } from '../types';

type CustomSearchSelectProps = {} & Omit<SearchSelectProps, 'isMulti'>;

const SearchSelect = (props: CustomSearchSelectProps) => {
  const { name, onChange } = props;

  const { getSearchSelectProps } = useSearchSelect({
    name,
  });
  const searchSelectProps = getSearchSelectProps({
    ...props,
    onChange: (selectedOption: OptionType) => {
      onChange?.(selectedOption?.value as string);
    },
  });

  return (
    <SelectWrapper {...props}>
      <Select {...searchSelectProps} />
    </SelectWrapper>
  );
};

export default SearchSelect;
