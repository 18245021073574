import CorientSubscriberInformationAmlTypes from './corientSubscriberInformationAmlTypes';

const corientSubscriberInformationAmlDefaults: CorientSubscriberInformationAmlTypes =
  {
    source_funds: '',
    purpose_investment: '',
    frequency_transactions: '',
    entity_investor_business: '',
  };

export default corientSubscriberInformationAmlDefaults;
