import {
  VStack,
  HStack,
  Switch,
  Text,
  Box,
  useColorMode,
  Icon,
} from '@chakra-ui/react';
import { AiFillSetting, AiOutlineCloseSquare } from 'react-icons/ai';

import { useState } from 'react';
import DevToolsWhiteLabelSelector from '~/utils/whiteLabel/DevToolsWhiteLabelSelector';
import DevToolNewSub from '../NewSubscriptionForm/DevToolNewSub';
import StyledButton from '../StyledButton';

const localStorage =
  typeof window !== 'undefined' ? window?.localStorage : undefined;

export const apiOrMockedData = localStorage?.getItem(
  'connected_to_api_or_mocked_data',
) as 'api' | 'mock';

const DevTools = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [showDevMenu, setShowDevMenu] = useState(false);
  const handleDevToolsButton = () => setShowDevMenu(prevValue => !prevValue);
  const pathName =
    typeof window !== 'undefined' ? window?.location?.pathname : '';

  const toggleConnectionMode = () => {
    if (typeof window === 'undefined') return;

    const toggleConnectionTo: 'api' | 'mock' =
      apiOrMockedData === 'mock' ? 'api' : 'mock';

    localStorage?.setItem(
      'connected_to_api_or_mocked_data',
      toggleConnectionTo,
    );
    localStorage?.setItem('advisor_override_id', '');
    window?.location.reload();
  };

  return (
    <>
      <Box position="absolute" right="3" top="6">
        <StyledButton
          bg="orange"
          borderRadius="0px"
          onClick={handleDevToolsButton}>
          <Icon as={AiFillSetting} color="white" />
        </StyledButton>
      </Box>
      {showDevMenu && (
        <VStack
          bg="gray.500"
          p="4"
          alignItems="start"
          position="absolute"
          spacing="8"
          top="5%"
          left="40%">
          <Icon
            as={AiOutlineCloseSquare}
            alignSelf="end"
            cursor="pointer"
            color="black"
            onClick={handleDevToolsButton}
          />
          {pathName ===
            '/qualified-purchasers/clients/new-subscription-form' && (
            <DevToolNewSub />
          )}
          <DevToolsWhiteLabelSelector />
          <HStack>
            <Text color="black">Toggle Theme:</Text>
            <Text color="green" fontWeight="bold">
              {colorMode === 'light' ? 'Light' : 'Dark'}
            </Text>
            <Switch onChange={toggleColorMode} />
          </HStack>
          <HStack>
            <Text color="black">Toggle API or Mocked data: </Text>
            <Text color="green" fontWeight="bold">
              {apiOrMockedData === 'mock' ? 'Mocked Data' : 'API'}
            </Text>
            <Switch onChange={toggleConnectionMode} />
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default DevTools;
