const offeringDocsFormDefaults = {
  fe_only_send_optional_offering_documents_checkbox: false,
  fe_only_offering_docs_emails: [],
  fe_only_offering_doc_auth_signer1: '',
  fe_only_offering_doc_auth_signer2: '',
  fe_only_offering_doc_auth_signer3: '',
  fe_only_offering_doc_auth_signer4: '',
  fe_only_offering_docs_is_required: false,
  offering_docs_email_fk: '',
};
export default offeringDocsFormDefaults;
