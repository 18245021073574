import { chakraComponents, DropdownIndicatorProps } from 'chakra-react-select';
import { Icon } from '@chakra-ui/react';
import { IoIosSearch } from 'react-icons/io';

type CustomDropdownIndicatorProps = {} & DropdownIndicatorProps;

const DropdownIndicator = ({
  selectProps,
  ...props
}: CustomDropdownIndicatorProps) => {
  const { isSearchable } = selectProps;
  return (
    <chakraComponents.DropdownIndicator selectProps={selectProps} {...props}>
      {isSearchable && <Icon as={IoIosSearch} color="gray.800" />}
    </chakraComponents.DropdownIndicator>
  );
};
export default DropdownIndicator;
