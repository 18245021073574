import {
  AiOutlineCheckCircle,
  AiOutlineInfoCircle,
  AiOutlineWarning,
} from 'react-icons/ai';
import colors from '~/theme/colors';

type ToastStyle = {
  color: string;
  bg: string;
  icon: any;
};

const TOAST_STATUSES_STYLE: {
  [key in 'success' | 'error' | 'warning' | 'info']: ToastStyle;
} = {
  success: {
    color: colors.green,
    bg: colors['dark.green'],
    icon: AiOutlineCheckCircle,
  },
  error: {
    color: colors.brand.error.light,

    bg: colors.brand.error.light,
    icon: AiOutlineInfoCircle,
  },
  warning: {
    color: colors.yellow,
    bg: colors.yellow,
    icon: AiOutlineWarning,
  },
  info: {
    color: colors.brand.perwinkle[500],
    bg: colors.brand.perwinkle[500],
    icon: AiOutlineInfoCircle,
  },
} as const;

export { TOAST_STATUSES_STYLE };
