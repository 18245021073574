import create, { StateCreator } from 'zustand';
import { FeatureFlagKeys } from '~/hooks/useFeatureFlag/type';

export interface SubscriptionStatusByClientFilterState {
  featureFlagKeys: FeatureFlagKeys[];
  setFeatureFlagKeys: (featureFlagKeys: FeatureFlagKeys[]) => void;
}

export const createFeatureFlagSlice: StateCreator<
  SubscriptionStatusByClientFilterState
> = set => ({
  featureFlagKeys: [],
  setFeatureFlagKeys: (featureFlagKeys: FeatureFlagKeys[]) =>
    set({ featureFlagKeys }),
});

const useFeatureFlagStore = create<SubscriptionStatusByClientFilterState>()(
  (...a) => ({
    ...createFeatureFlagSlice(...a),
  }),
);

export default useFeatureFlagStore;
