import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
    /* Fraktion Sans Bold */
    @font-face {
      font-family: 'Fraktion Sans';
      src: url("/fonts/Fraktion_Sans_Bold.ttf") format('truetype');
      font-weight: 700 1000;
    }
    
    /* Fraktion Sans Normal */
    @font-face {
      font-family: 'Fraktion Sans';
      src: url("/fonts/Fraktion_Sans_Regular.ttf") format('truetype');
      font-weight: 400 600;
    }

    /* Fraktion Sans Light*/
    @font-face {
      font-family: 'Fraktion Sans';
      src: url("/fonts/Fraktion_Sans_Light.ttf") format('truetype');
      font-weight: 100 300;
    }
      `}
  />
);
// src: url("/fonts/Fraktion_Sans_Regular.ttf") format('truetype') font-weight-regular, url("/fonts/Fraktion_Sans_Bold.ttf") format('truetype') font-weight-bold,url("/fonts/Fraktion_Sans_Light.ttf") format('truetype') font-weight-light;

export default Fonts;
