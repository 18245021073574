import { WHITE_LABEL_PARTNER_TEMPLATE_CONFIG } from 'utils/whiteLabel/defaultTypes';
import capitalizeSentence from 'utils/capitalizeSentence';
import { OptionType } from '~/components/Form/Selects/types';

export const WHITE_LABEL_PARTNERS: OptionType[] = [
  {
    label: `JP Morgan (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.jpmorgan.theme,
    )})`,
    value: 'jpmorgan',
  },
  {
    label: `Blackstone (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.blackstone.theme,
    )})`,
    value: 'blackstone',
  },
  {
    label: `Morgan Stanley (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.morganstanley.theme,
    )})`,
    value: 'morganstanley',
  },
  {
    label: `Gries Financial Partners (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.gries.theme,
    )})`,
    value: 'gries',
  },
  {
    label: `Clearstead (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.clearstead.theme,
    )})`,
    value: 'clearstead',
  },
  {
    label: `QP Wealth Management (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.qpwealthmanagement.theme,
    )})`,
    value: 'qpwealthmanagement',
  },
  {
    label: `Fifth Third Bank (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG[53].theme,
    )})`,
    value: '53',
  },
  {
    label: `Apollo (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.apollo.theme,
    )})`,
    value: 'apollo',
  },
  {
    label: `PIMCO (Default Theme: ${capitalizeSentence(
      WHITE_LABEL_PARTNER_TEMPLATE_CONFIG.pimco.theme,
    )})`,
    value: 'pimco',
  },
  {
    label: 'GlasFunds',
    value: 'glasfunds',
  },
];

export default WHITE_LABEL_PARTNERS;
