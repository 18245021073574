export default function capitalizeSentence(
  sentence: string,
  allLetters: boolean = false,
) {
  if (typeof sentence !== 'string' || sentence?.trim() === '') return '';
  if (allLetters) return sentence.toUpperCase();
  const words = sentence.split(' ');

  return words
    ?.map(word => word?.[0]?.toUpperCase() + word?.substring(1))
    ?.join(' ');
}
