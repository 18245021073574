import colors, { StyleOptions } from '../colors';

const Checkbox = {
  baseStyle: ({ colorMode }: StyleOptions) => ({
    label: {
      color: colors.gray['800'],

      _disabled: {
        color: colors.gray['800'],
        opacity: colorMode === 'light' ? '0.8' : '0.5',
      },
    },
    control: {
      borderRadius: 0,
      border: '2px solid',
      borderColor: colors.gray['500'],

      _hover: {
        borderColor: colors.brand['warm.gray'],
      },

      _invalid: {
        borderColor:
          colorMode === 'light'
            ? colors.brand.error.light
            : colors.brand.error.dark,
      },

      _disabled: {
        borderColor:
          colorMode === 'light'
            ? colors.gray['500.o50.light']
            : colors.gray['500.o50.dark'],
        bg: 'transparent',

        _hover: {
          borderColor: colors.gray['500'],
        },
      },

      _checked: {
        bg: colors.brand.perwinkle['500'],
        border: 'none',

        _disabled: {
          bg:
            colorMode === 'light'
              ? colors.brand.perwinkle['500.o50.light']
              : colors.brand.perwinkle['500.o50.dark'],
        },

        _hover: {
          bg: colors.brand.perwinkle['500'],
        },
      },
    },
  }),
};
export default Checkbox;
