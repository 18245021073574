import { useColorMode } from '@chakra-ui/react';
import { useLayoutEffect } from 'react';
import getWhiteLabelPartner from './index';
import {
  DEFAULT_GLAS_TEMPLATE_CONFIG,
  WHITE_LABEL_PARTNER_TEMPLATE_CONFIG,
} from './defaultTypes';

const useHandleToggleTheme = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const whiteLabelPartner = getWhiteLabelPartner();

  const whitLabelPartnerConfig = whiteLabelPartner
    ? WHITE_LABEL_PARTNER_TEMPLATE_CONFIG[whiteLabelPartner]
    : DEFAULT_GLAS_TEMPLATE_CONFIG;
  const href = typeof window !== 'undefined' ? window?.location?.href : '';
  const isLightThemeSelected = href.includes('light-theme');
  const isDarkThemeSelected = href.includes('dark-theme');
  const lightWhiteLabelPartnerTheme = whitLabelPartnerConfig?.theme === 'light';
  const darkWhiteLabelPartnerTheme = whitLabelPartnerConfig?.theme === 'dark';

  const shouldToggleTheme = {
    light: isDarkThemeSelected || darkWhiteLabelPartnerTheme,
    dark: isLightThemeSelected || lightWhiteLabelPartnerTheme,
  };

  useLayoutEffect(() => {
    if (shouldToggleTheme[colorMode]) {
      if (process.env.NEXT_PUBLIC_STAGING !== 'true') toggleColorMode();
    }
  }, [shouldToggleTheme[colorMode]]);
};
export default useHandleToggleTheme;
