/* eslint-disable camelcase */
import {
  CorientAccountInformationData,
  CorientAccountInformationFEData,
  CorientAccountInformationBEData,
} from './corientAccountInformationTypes';

export const corientAccountInformationBEDefaults: CorientAccountInformationBEData =
  {
    signer_1_country: '',
    signer_2_country: '',
    tamarac_id: '',
    black_diamond_account_number: '',
    orion_account_number: '',
    orion_client_id: '',
    orion_registration_id: '',
  };

export const corientAccountInformationFEDefaults: CorientAccountInformationFEData =
  {
    fe_only_reporting_software: null,
  };

export const corientAccountInformationDefaults: CorientAccountInformationData =
  {
    ...corientAccountInformationBEDefaults,
    ...corientAccountInformationFEDefaults,
  };
