import { CircularProgress, HStack, Text } from '@chakra-ui/react';
import useBgThemeColor from '~/hooks/themeColors/useBgThemeColor';

const CustomLoadingMenuFooter = () => {
  const primaryBg = useBgThemeColor();
  return (
    <HStack p="4" bg={primaryBg} aria-label="Paginated Dropdown Loader">
      <CircularProgress
        isIndeterminate
        color="brand.perwinkle.500"
        thickness="5px"
        size="15px"
      />
      <Text>Loading...</Text>
    </HStack>
  );
};

export default CustomLoadingMenuFooter;
