import { corientBadActorDefaults } from '../CorientBadActor/defaults/badActorDefaults';
import corientSubscriberInformationAmlDefaults from '../CorientSubscriberInformationAmlSection/form/corientSubscriberInformationAmlDefaults';
import CorientSubscriberInformationTypes, {
  CORIENT_SIGNER,
} from './corientSubscriberInformationTypes';

export const CORIENT_SIGNER_DEFAULT: CORIENT_SIGNER = {
  name: '',
  title: '',
  email: '',
  birthdate: '',
};

const corientSubscriberInformationDefaults: CorientSubscriberInformationTypes =
  {
    signers: [CORIENT_SIGNER_DEFAULT],
    primary_subscriber_occupation: '',
    country_of_form: '',
    state_of_form: '',
    date_of_form: '',
    corient_affiliate: '',
    corient_affiliate_name: '',
    corient_affiliate_email: '',
    corient_affiliate_relationship: '',
    exempt_payee_code: '',
    exempt_from_fatca_reporting: '',
    ...corientBadActorDefaults,
    ...corientSubscriberInformationAmlDefaults,
  };

export { corientSubscriberInformationDefaults };
