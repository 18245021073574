export interface StyleOptions {
  colorMode: 'light' | 'dark';
}

const colors = {
  brand: {
    'cool.gray': '#768692',
    navy: '#1B365D',
    'navy.darken': '#1B365D60',
    perwinkle: {
      '500': '#05C3DD',
      '500.dark': '#00606e',
      '500.o10': '#05C3DD52',
      '500.o10.light': '#E6F9FC',
      '500.o25': '#05C3DD3D',
      '500.o25.light': '#41D1E5',
      '500.o25.dark': '#C3F1F7',
      '500.o50.light': '#79D5E1',
      '500.o50.dark': '#0B6A7B',
      '800': '#505F88',
    },
    'warm.gray': '#4797A8',
    yellow: '#FBB160',
    green: '#239A68',
    'green.500': '#239A68',
    red: '#EC4E3D',
    error: {
      light: '#B00020',
      'light.10': '#f7e6e9',
      dark: '#CF6679',
      'dark.035': '#CF667959',
      'dark.010': '#CF66791A',
    },
  },
  gray: {
    '900': '#101820',
    '890': '#192129',
    '870': '#3F464C',
    '870.o30': '#3F464C4D',
    '850': '#27272A',
    '840': '#5A636D',
    '810': '#313135',
    '805': '#424246',
    '800': '#6F7478',
    '800.o9': '#212A31',
    '800.o10': '#6F74781A',
    '800.o60': '#6F747899',
    '800.o30': '#6F74784D',
    '750': '#9EA2A5',
    '700': '#CFD0D2',
    '600.o10': '#EEF2F31A',
    '600': '#EEF2F3',
    '500.o50.light': '#E5E8EA',
    '500.o50.dark': '#777D83',
    '500': '#E6EBEF',
    '460': '#DDE5E8',
    '450': '#F3F5F4',
    '400': '#F7F8FA',
    '300': '#FDFDFD',
  },
  green: '#3CC13B',
  'dark.green': '#1B5E20',
  yellow: '#F3BB1C',
  red: '#CF6679',
  navyColorScheme: {
    '500': '#1B365D',
  },
  coolGrayScheme: {
    '200': '#768692',
  },
} as const;

export default colors;
