import { GroupBase, SelectComponentsConfig } from 'chakra-react-select';
import DropdownIndicator from '~/components/Form/Selects/components/DropdownIndicator';
import Input from '~/components/Form/Selects/components/Input';
import CustomMenuList from '~/components/Form/Selects/components/CustomMenuList';
import Option from '~/components/Form/Selects/components/Option';
import ClearIndicator from '~/components/Form/Selects/components/ClearIndicator';

type CustomSelectComponents = Partial<
  SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>
>;

type GetComponentsProps = {} & CustomSelectComponents;

type GetComponentsResult = {
  components: CustomSelectComponents;
};

const getComponents = (
  getComponentsProps: GetComponentsProps = {},
): GetComponentsResult => ({
  components: {
    Input,
    DropdownIndicator,
    Option,
    MenuList: CustomMenuList,
    ClearIndicator,
    ...getComponentsProps,
  },
});

export default getComponents;
