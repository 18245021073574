export const GA_TRACKING_ID = process.env
  .NEXT_PUBLIC_GOOGLE_ANALYTICS as string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!window) return;
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

type Event = {
  action: string;
  category?: 'Button' | 'Search' | 'Input' | 'Submit' | 'Link' | 'Tab';
  label?: string;
  value?: string;
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: Event) => {
  if (!window) return;
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
