import { useEffect } from 'react';
import useFeatureFlagStore from '~/store/useFeatureFlagStore';
import { FeatureFlagKeys } from './type';

const useFeatureFlag = (featureKey?: FeatureFlagKeys) => {
  const setFeatureFlagKeys = useFeatureFlagStore(
    store => store.setFeatureFlagKeys,
  );
  const featureFlagKeys = useFeatureFlagStore(store => store.featureFlagKeys);

  const featureFlagString = process.env.NEXT_PUBLIC_FEATURE_FLAG_KEYS;
  const featureFlagsSeparated =
    (featureFlagString?.split('-&-') as unknown as FeatureFlagKeys[]) || [];

  useEffect(() => {
    setFeatureFlagKeys(featureFlagsSeparated);
  }, []);

  if (!featureKey) return false;
  const isFeatureFlagged = !!featureFlagKeys.includes(featureKey);

  return isFeatureFlagged;
};

export default useFeatureFlag;
