import GriesLogo from 'assets/images/GriesLogo.png';
import GriesDarkLogo from 'assets/images/GriesDarkLogo.png';
import ClearsteadLogo from 'assets/images/ClearsteadLogo.png';
import ClearsteadDarkLogo from 'assets/images/ClearsteadDarkLogo.png';
import QPLogo from 'assets/images/QPLogo.png';
import QPDarkLogo from 'assets/images/QPDarkLogo.png';
import FifthThirdLogo from 'assets/images/53Logo.png';
import FifthThirdDarkLogo from 'assets/images/53DarkLogo.png';
import ApolloLogo from 'assets/images/ApolloLogo.png';
import ApolloDarkLogo from 'assets/images/ApolloDarkLogo.png';
import PimcoLogo from 'assets/images/PimcoLogo.png';
import PimcoDarkLogo from 'assets/images/PimcoDarkLogo.png';
import JPMorganLogo from 'assets/images/JPMorganLogo.png';
import BlackstoneLogo from 'assets/images/BlackstoneLogo.png';
import MorganStanleyLogo from 'assets/images/MorganStanleyLogo.png';

export const WHITE_LABEL_PARTNERS = {
  gries: 'gries',
  clearstead: 'clearstead',
  qpwealthmanagement: 'qpwealthmanagement',
  '53': '53',
  apollo: 'apollo',
  pimco: 'pimco',
  jpmorgan: 'jpmorgan',
  blackstone: 'blackstone',
  morganstanley: 'morganstanley',
} as const;

export type WhiteLabelPartners = keyof typeof WHITE_LABEL_PARTNERS;

export type WhiteLabelType = typeof WHITE_LABEL_PARTNERS[WhiteLabelPartners];

export type whiteLabelPartnerConfig = {
  theme: string;
  logo: { light: any; dark: any };
  color?: string;
};

const hostName =
  typeof window !== 'undefined' ? window?.location?.hostname : '';
export const DEFAULT_GLAS_TEMPLATE_CONFIG = {
  theme: hostName?.includes('sandbox') ? 'dark' : 'light',
  logo: 'assets/images/GLASfunds_dark_logo.svg',
} as const;

export const WHITE_LABEL_PARTNER_TEMPLATE_CONFIG: {
  [key: string]: whiteLabelPartnerConfig;
} = {
  gries: {
    theme: 'light',
    logo: {
      light: GriesLogo,
      dark: GriesDarkLogo,
    },
  },
  clearstead: {
    theme: 'light',
    logo: {
      light: ClearsteadLogo,
      dark: ClearsteadDarkLogo,
    },
  },
  qpwealthmanagement: {
    theme: 'light',
    logo: {
      light: QPLogo,
      dark: QPDarkLogo,
    },
  },
  '53': {
    theme: 'dark',
    logo: {
      light: FifthThirdLogo,
      dark: FifthThirdDarkLogo,
    },
  },
  apollo: {
    theme: 'light',
    logo: {
      light: ApolloLogo,
      dark: ApolloDarkLogo,
    },
    color: '#008969',
  },
  pimco: {
    theme: 'light',
    logo: {
      light: PimcoLogo,
      dark: PimcoDarkLogo,
    },
    color: '#0875AF',
  },
  jpmorgan: {
    theme: 'light',
    logo: {
      light: JPMorganLogo,
      dark: JPMorganLogo,
    },
    color: '#936846',
  },
  blackstone: {
    theme: 'light',
    logo: {
      light: BlackstoneLogo,
      dark: BlackstoneLogo,
    },
    color: '#000000',
  },
  morganstanley: {
    theme: 'light',
    logo: {
      light: MorganStanleyLogo,
      dark: MorganStanleyLogo,
    },
    color: '#096491',
  },
};
