import create, { StateCreator } from 'zustand';

export interface HistoryState {
  history: string[];
  addHistoryEntry: (history: string) => void;
}

export const createHistorySlice: StateCreator<HistoryState> = set => ({
  history: [],
  addHistoryEntry: (history: string) =>
    set(state => ({ history: [...state.history, history] })),
});

const useHistoryStore = create<HistoryState>()((...a) => ({
  ...createHistorySlice(...a),
}));

export default useHistoryStore;
