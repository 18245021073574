import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: colors.gray[900],
    _selected: {
      bg: colors.brand.perwinkle[500],
    },
    _focus: {
      bg: colors.brand.perwinkle[500],
    },
  },
});
// export the base styles in the component theme
export const Menu = defineMultiStyleConfig({ baseStyle });
