import { createStandaloneToast, ToastPosition } from '@chakra-ui/toast';
import theme from '~/theme';
import CustomToast, { CustomToastProps } from './CustomToast';

type ToastProps = {
  position?: ToastPosition;
  duration?: number;
  status?: CustomToastProps['status'];
};

const { ToastContainer, toast } = createStandaloneToast({ theme });

const Toast = (
  { position = 'top-right', duration = 3000, status = 'info' }: ToastProps = {
    position: 'top-right',
    duration: 3000,
    status: 'info',
  },
) => {
  const customToastFn = (customToastProps: Omit<CustomToastProps, 'onClose'>) =>
    toast({
      position,
      duration,
      render: props => (
        <CustomToast
          status={status}
          {...props}
          {...(customToastProps as any)}
        />
      ),
    }) as unknown as void;

  return customToastFn;
};

export { ToastContainer };

export default Toast;
