import {
  ERISAQuestionnaireTypes,
  ERISAQuestionnaireKeys,
  ERISAQuestionnaireBETypes,
  ERISAQuestionnaireFETypes,
} from './erisaQuestionnaireTypes';

export const erisaQuestionnaireBEDefaults: ERISAQuestionnaireBETypes = {
  erisa_data_labels: [],
};

export const erisaQuestionnaireFEDefaults: ERISAQuestionnaireFETypes = {
  fe_only_erisa_first_representations: '',
  fe_only_erisa_benefit_plan_type: '',
  fe_only_erisa_non_participant_directed_plan_representations: '',
  fe_only_erisa_participant_directed_plan_representations: '',
  fe_only_erisa_display_invalid_answers_modal: 'NOT_NEEDED',
};

export const erisaQuestionnaireDefaults: ERISAQuestionnaireTypes = {
  ...erisaQuestionnaireFEDefaults,
  ...erisaQuestionnaireBEDefaults,
};

export const erisaQuestionnaireKeysDefaults = Object.keys(
  erisaQuestionnaireDefaults,
) as ERISAQuestionnaireKeys[];
