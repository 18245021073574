import { Button, ButtonProps } from '@chakra-ui/react';
import {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FeatureFlagKeys } from '~/hooks/useFeatureFlag/type';
import useFeatureFlag from '~/hooks/useFeatureFlag/useFeatureFlag';
import { RIPPLE_EFFECT_DURATION } from '~/theme/components/ripplebox';
import RippleEffect from './RippleEffect';

export type StyledButtonProps = {
  children?: string | ReactNode;
  featureFlagKey?: FeatureFlagKeys;
} & ButtonProps;

const StyledButton: ForwardRefRenderFunction<
  HTMLInputElement,
  StyledButtonProps
> = ({ children, onClick, featureFlagKey, ...props }, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [ripple, setRipple] = useState<ReactElement>();

  const { variant } = props;

  useEffect(() => {
    if (!ripple) return;
    setTimeout(() => {
      setRipple(undefined);
    }, RIPPLE_EFFECT_DURATION);
  }, [ripple]);

  const createRipple = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = buttonRef.current;
    if (button) {
      setRipple(
        <RippleEffect
          button={button}
          event={event}
          variant={{ variant }}
          ref={ref}
        />,
      );
    }
  }, []);

  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    createRipple(e);
    onClick?.(e);
  };

  const isFeatureFlagged = useFeatureFlag(featureFlagKey);

  if (isFeatureFlagged) return null;
  return (
    <>
      <Button ref={buttonRef} {...props} onClick={handleClick}>
        {children}
        {ripple}
      </Button>
    </>
  );
};

export default memo(forwardRef(StyledButton));
