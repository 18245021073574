import { QueryFunctionContext } from 'react-query';
import api from 'services/api';
import { ResponseType } from 'axios';
import getCustomHeaders from './getCustomHeaders';

export type QueryKeyFilters = [{ filters: string }];

type DefaultQueryFn = {
  meta?: { responseType?: ResponseType };
} & QueryFunctionContext;

const defaultQueryFn = async ({
  queryKey,
  pageParam,
  meta,
}: DefaultQueryFn) => {
  const [queryParam] = queryKey as string[];
  const [filters] = queryKey?.filter(
    key => typeof key === 'object',
  ) as QueryKeyFilters;
  const { responseType } = meta || {};

  const filtersToApply = filters?.filters || {};

  const { data } = await api.get(queryParam, {
    responseType,
    headers: getCustomHeaders(),
    params: {
      ...filtersToApply,
      _page: pageParam,
    },
  });

  return data || {};
};
export default defaultQueryFn;
