import { FC, useEffect, useState } from 'react';
import Toast from '../Toast';
import AdvisorOverrideContext from './AdvisorOverrideContext';

const AdvisorOverrideProvider: FC = ({ children }) => {
  const [advisorOverrideId, setAdvisorOverrideId] = useState<string>(() => {
    try {
      if (typeof window !== 'undefined') {
        const storedAdvisorOverrideId = JSON.parse(
          window.localStorage.getItem('advisor_override_id') || '',
        );

        if (storedAdvisorOverrideId) return storedAdvisorOverrideId;
      }
    } catch (error) {
      return '';
    }
    return '';
  });

  const errorToast = Toast({ status: 'error' });

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(
          'advisor_override_id',
          JSON.stringify(advisorOverrideId),
        );
      }
    } catch (error) {
      errorToast({
        title: 'Error Saving the advisor to override.',
        description: 'Please try to select the advisor again.',
      });
    }
  }, [advisorOverrideId]);

  return (
    <AdvisorOverrideContext.Provider
      value={[advisorOverrideId, setAdvisorOverrideId]}>
      {children}
    </AdvisorOverrideContext.Provider>
  );
};

export default AdvisorOverrideProvider;
