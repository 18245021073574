import { useColorModeValue } from '@chakra-ui/react';
import colors from '~/theme/colors';

const usePrimaryTextThemeColor = () => {
  const primaryTextColors = useColorModeValue(
    colors.gray[900],
    colors.gray[400],
  );
  return primaryTextColors;
};
export default usePrimaryTextThemeColor;
