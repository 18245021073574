import { HStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchSelect from '~/components/Form/Selects/SearchSelect';
import StyledButton from '~/components/StyledButton';
import { WHITE_LABEL_PARTNERS } from './whiteLabelTypes';

const DevToolsWhiteLabelSelector = () => {
  const { handleSubmit, ...methods } = useForm({});

  const whiteLabelPartnersOrderedAlphabetically = WHITE_LABEL_PARTNERS.sort(
    (whiteLabel1, whiteLabel2) =>
      whiteLabel1.label.localeCompare(whiteLabel2.label),
  );

  const handleSelectWhiteLabel = (data: any) => {
    let localStorage: any;
    let location: any;
    if (typeof window !== 'undefined') ({ localStorage, location } = window);

    if (!data?.dev_tool_white_label_partner) return;

    localStorage?.setItem(
      'white-label-partner',
      data?.dev_tool_white_label_partner,
    );
    location.reload(true);
  };

  return (
    <FormProvider {...{ handleSubmit, ...methods }}>
      <HStack as="form" onSubmit={handleSubmit(handleSelectWhiteLabel)}>
        <SearchSelect
          options={whiteLabelPartnersOrderedAlphabetically}
          width="400px"
          label="Select White Label Partner"
          name="dev_tool_white_label_partner"
          filterMachFromStart={false}
        />
        <StyledButton type="submit" minW="100px">
          Submit
        </StyledButton>
      </HStack>
    </FormProvider>
  );
};

export default DevToolsWhiteLabelSelector;
