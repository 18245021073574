import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import colors from '../colors';

const helpers = createMultiStyleConfigHelpers(['container', 'thumb', 'track']);
const Switch = helpers.defineMultiStyleConfig({
  baseStyle: {
    thumb: {
      bg: 'white',
    },
    track: {
      bg: colors.gray[700],
      _checked: {
        bg: colors.brand.perwinkle[500],
      },
    },
  },
});

export default Switch as any;
