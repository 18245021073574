import colors, { StyleOptions } from '../colors';

const Input = {
  variants: {
    solid: () => ({}),
    outline: () => ({}),
  },
  baseStyle: ({ colorMode }: StyleOptions) => ({
    field: {
      fontWeight: 'bold',
      borderBottom: '1.5px solid',
      bg: colorMode === 'light' ? colors.gray['300'] : colors.gray['800.o10'],
      borderColor: colors.gray['700'],

      _invalid: {
        borderColor:
          colorMode === 'light'
            ? colors.brand.error.light
            : colors.brand.error.dark,
      },

      _readOnly: {
        bg: colorMode === 'light' ? colors.gray['400'] : colors.gray['870.o30'],
        color: colorMode === 'light' ? colors.gray['700'] : colors.gray['800'],
        borderBottom: 'none',
        cursor: 'not-allowed',
      },

      _disabled: {
        bg: colorMode === 'light' ? colors.gray['400'] : colors.gray['870.o30'],
        color: colorMode === 'light' ? colors.gray['700'] : colors.gray['800'],
        borderBottom: 'none',
        cursor: 'not-allowed',
      },

      _hover: {
        borderColor: colors.brand['warm.gray'],

        _invalid: {
          borderColor:
            colorMode === 'light'
              ? colors.brand.error.light
              : colors.brand.error.dark,
        },
      },

      _focus: {
        bg: colorMode === 'light' ? colors.gray['600'] : colors.gray['600.o10'],
        borderColor: colors.brand.perwinkle['500'],

        _invalid: {
          borderColor: 'red',
        },

        _hover: {
          borderColor: colors.brand.perwinkle['500'],

          _invalid: {
            borderColor: 'red',
          },
        },
      },
    },
  }),
  sizes: {
    sm: {
      field: {
        top: 0,
        left: 0,
        pt: 8,
        pb: 2,
        px: 4,
        fontSize: 'md',
        h: '48px',
        rounded: '0',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'sm',
  },
};

export default Input;
