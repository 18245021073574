import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { Auth0Provider } from '@auth0/auth0-react';
import { NextPage } from 'next';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import ChakraColorManagerProvider from 'components/ChakraColorManagerProvider';
import theme from '~/theme';
import Fonts from 'components/Fonts';
import { apiOrMockedData } from '~/components/DevTools/DevTools';
import Analytics from '~/components/Analytics';
import { hotjar } from 'react-hotjar';
import useMaintenanceModeRefreshManager from '~/hooks/useMaintenanceModeRefreshManager';
import useHistory from '~/hooks/useHistory';
import { ColorModeScript, extendTheme } from '@chakra-ui/react';
import { QueryClient } from 'react-query';
import defaultQueryFn from '~/utils/defaultQueryFn';
import { ToastContainer } from '~/components/Toast/Toast';
/** import { GLAStheme } from '@glasfunds/glas-component-lib'; */
import App from 'next/app';
import ReactQueryApp from '../components/ReactQueryApp/ReactQueryApp';

if (
  process.env.NEXT_PUBLIC_STAGING === 'true' &&
  typeof window !== 'undefined'
) {
  import('../mocks').then(({ setupMocks }) => {
    if (apiOrMockedData === 'mock') setupMocks();
  });
}

export type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  nonce: string;
};

function MyApp(props: AppPropsWithLayout) {
  useMaintenanceModeRefreshManager();
  useHistory();

  /**
   * First, we need to correct the color disparity between the two themes
   * before replacing the current one with the GLAStheme. Otherwise, we'll
   * need to go through all possible flows in the project to fix styling errors.
   */
  const chakraTheme = extendTheme(theme); /** GLAStheme */

  const {
    pageProps: { cookies },
    nonce,
  } = props;

  const nonceRef = useRef(nonce);

  /**
   * After React's update (18.2.0), we need to display the page
   * after pre-rendering is done to prevent hydration errors.
   */
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    const nextPublicHotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;
    const nextPublicHotjarVersion = process.env.NEXT_PUBLIC_HOTJAR_VERSION;

    if (nextPublicHotjarId && nextPublicHotjarVersion) {
      hotjar.initialize(
        Number(nextPublicHotjarId),
        Number(nextPublicHotjarVersion),
      );
    }
  }, []);

  const redirectUrl =
    typeof window !== 'undefined' ? window.location.origin : '';

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryFn: defaultQueryFn,
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  if (!isMounted) return null;

  return (
    <>
      <ChakraColorManagerProvider cookies={cookies} theme={chakraTheme}>
        <ToastContainer />
        <ColorModeScript
          initialColorMode={chakraTheme.config.initialColorMode}
        />
        <Fonts />
        <Auth0Provider
          redirectUri={redirectUrl}
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
          audience={`${process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}/api/v2/`}
          scope="read:current_user update:current_user_metadata"
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}>
          <Head>
            <title>GLASfunds Investor Portal</title>
          </Head>
          <ReactQueryApp {...props} queryClient={queryClient} />
        </Auth0Provider>
      </ChakraColorManagerProvider>
      <Analytics nonce={nonceRef.current} />
    </>
  );
}

MyApp.getInitialProps = async (
  Context: AppContext,
): Promise<{ nonce: string } & AppInitialProps> => {
  const props = await App.getInitialProps(Context);

  const {
    ctx: { req },
  } = Context;

  const nonce = (req?.headers?.['x-nonce'] ?? '') as string;

  return {
    ...props,
    nonce,
  };
};

export { getServerSideProps } from '~/components/ChakraColorManagerProvider/ChakraColorManagerProvider';

export default MyApp;
