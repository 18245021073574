import {
  WhiteLabelType,
  WhiteLabelPartners,
  WHITE_LABEL_PARTNERS,
} from './defaultTypes';

const isValidWhiteLabel = (
  whiteLabelPartner: string,
): whiteLabelPartner is WhiteLabelPartners =>
  whiteLabelPartner in WHITE_LABEL_PARTNERS;

const getQueryWhiteLabelPartner = (): WhiteLabelType | false => {
  const partnerNameInQuery =
    typeof window !== 'undefined'
      ? window?.location?.search.split('=')?.[1]
      : '';

  if (isValidWhiteLabel(partnerNameInQuery))
    return WHITE_LABEL_PARTNERS[partnerNameInQuery];
  return false;
};

const getSubdomainWhiteLabelPartner = (): WhiteLabelType | false => {
  const href = typeof window !== 'undefined' ? window?.location?.href : '';
  const subdomainStartIndex = href?.indexOf('//') + 2;
  const subdomainEndIndex = href?.indexOf('.');
  const partnerNameInSubdomain = href?.substring(
    subdomainStartIndex,
    subdomainEndIndex,
  );

  if (isValidWhiteLabel(partnerNameInSubdomain))
    return WHITE_LABEL_PARTNERS[partnerNameInSubdomain];
  return false;
};

const getDevToolsWhiteLabelPartner = (): WhiteLabelType | false => {
  if (process.env.NEXT_PUBLIC_STAGING === 'true') {
    let localStorage: any;
    if (typeof window !== 'undefined') ({ localStorage } = window);

    const forcedWhiteLabel = localStorage?.getItem('white-label-partner');

    if (isValidWhiteLabel(forcedWhiteLabel)) {
      return WHITE_LABEL_PARTNERS[forcedWhiteLabel];
    }
  }
  return false;
};

const getWhiteLabelPartner = (): WhiteLabelType | false => {
  const devToolsWhiteLabelPartner = getDevToolsWhiteLabelPartner();
  if (devToolsWhiteLabelPartner) return devToolsWhiteLabelPartner;

  const subdomainWhiteLabelPartner = getSubdomainWhiteLabelPartner();
  if (subdomainWhiteLabelPartner) return subdomainWhiteLabelPartner;

  const queryWhiteLabelPartner = getQueryWhiteLabelPartner();
  if (queryWhiteLabelPartner) return queryWhiteLabelPartner;

  return false;
};

export default getWhiteLabelPartner;
