import getWhiteLabelPartner from '~/utils/whiteLabel';

const getCustomHeaders = () => {
  const whiteLabelHeader = {
    'white-label-domain': getWhiteLabelPartner() || 'portal',
  };

  return { ...whiteLabelHeader };
};

export default getCustomHeaders;
