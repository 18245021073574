import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useHistoryStore from '~/store/useHistoryStore';

const useHistory = () => {
  const router = useRouter();

  const addHistoryEntry = useHistoryStore(store => store.addHistoryEntry);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      addHistoryEntry(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};

export default useHistory;
