import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

const useValidateFieldWithErrorOnChange = (name: string) => {
  const {
    formState: { errors },
    trigger,
    watch,
  } = useFormContext();
  const fieldValue = watch(name);

  useUpdateEffect(() => {
    /**
     * Needs to use the lodash get, because of field arrays so it can track nested errors
     */
    const errorByFieldName = get(errors, name);

    if (errorByFieldName) {
      trigger(name);
    }

    const isArrayField = name.includes('.');
    const arrayFieldName = name.split('.')[0];
    const arrayFieldError = get(errors, arrayFieldName);

    if (isArrayField && arrayFieldError) trigger(arrayFieldName);
  }, [fieldValue]);
};

export default useValidateFieldWithErrorOnChange;
