import colors, { StyleOptions } from '../colors';

const Radio = {
  baseStyle: ({ colorMode }: StyleOptions) => ({
    label: {
      color: colors.gray['800'],

      _disabled: {
        cursor: 'not-allowed',
      },

      _checked: {
        color: colors.brand.perwinkle['500'],
      },
    },
    control: {
      border: '2px solid',
      borderColor: colors.gray['500'],

      _hover: {
        borderColor: colors.brand['warm.gray'],

        _disabled: {
          borderColor: colors.gray['500'],
        },
      },

      _invalid: {
        borderColor:
          colorMode === 'light'
            ? colors.brand.error.light
            : colors.brand.error.dark,
      },

      _disabled: {
        borderColor: colors.gray['500'],
        cursor: 'not-allowed',
      },

      _checked: {
        bg: colors.brand.perwinkle['500'],
        border: 'none',

        _disabled: {
          bg:
            colorMode === 'light'
              ? colors.brand.perwinkle['500.o50.light']
              : colors.brand.perwinkle['500.o50.dark'],
        },

        _hover: {
          bg: colors.brand.perwinkle['500'],
        },
      },
    },
  }),
};
export default Radio;
